import React, { Component } from 'react';
import ReactGA from 'react-ga';

class CatchAllPage extends Component{
    componentDidMount(){ 
        window.scrollTo(0, 0); document.title = "404";
        // Google Analytics
        ReactGA.initialize('UA-206329520-1'); ReactGA.pageview('/404');
    }
    render(){  
        return(
            <div className="page-body 404">
                <h1>404</h1>
            </div>
        );
    }
}
export default CatchAllPage;