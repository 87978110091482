import React, { Component } from 'react';
import { Router, Route, Switch, Link } from "react-router-dom";
import { createBrowserHistory } from 'history';

/* Images */
import logo_c from '../assets/logos/logo_base.png';

/* Styles */
import "../css/app.less";

import CatchAllPage from './components/404';
import ContactUs from './components/contactus';
import FAQ from './components/faq';
import Home from './components/home';
import Services from './components/services';
//import UC from './components/uc';


/* Constants */
const history = createBrowserHistory(); 
const rootPath = ( window.location.href.indexOf("localhost") > -1  ? "http://localhost:1521" : "");

const routes = [
    { path:"/services", component: Services },
    { path:"/faq", component:FAQ },
    { path:"/contactus", component:ContactUs }
];

const SiteRoutes = route => (
    <Route path={route.path} render={props => ( <route.component {...props} rootPath={rootPath} />)} />
);

function MobileNav(props){       
    return (
        <div className={"sidenav-container" + (props.sidebarOpen ? " active": "")}>
            <div className="sidenav-section close-section">
                <div className="nav-close" onClick={() => props.setSidebarDisplay(false)}><span className="close-nav" /></div>
            </div>

            <div className="sidenav-section">
                <Link className="sidenav-link" to="/" onClick={() => props.setSidebarDisplay(false)}><span>Home</span></Link>
                <Link className="sidenav-link" to="/services" onClick={() => props.setSidebarDisplay(false)}><span>Services</span></Link>
                <Link className="sidenav-link" to="/services#drinks" onClick={() => props.setSidebarDisplay(false)}><span>Drinks</span></Link>
                <Link className="sidenav-link" to="/faq" onClick={() => props.setSidebarDisplay(false)}><span>FAQ</span></Link>
                <Link className="sidenav-link" to="/contactus" onClick={() => props.setSidebarDisplay(false)}><span>Book Now</span></Link>
            </div>

            <div className="sidenav-section">
                <h2>Follow Us</h2>
                <div className='follow-container'>
                    <a href="https://www.instagram.com/reysrosemobilebar/" target="_blank" className='follow-btn' onClick={() => props.setSidebarDisplay(false)}><i className="fab fa-instagram"/></a>
                    <a href="https://www.facebook.com/reysrosemobilebar" target="_blank" className='follow-btn' onClick={() => props.setSidebarDisplay(false)}><i className="fab fa-facebook"/></a>
                </div>
            </div>
        </div>
    );
}

class App extends Component{
    constructor(props) { 
        super(props);
        this.state = { copyrightDate: 2022, sidebarOpen: false, navChange: false }

        this.listenToScroll = this.listenToScroll.bind(this);
        this.setSidebarDisplay = this.setSidebarDisplay.bind(this);
    }   

    listenToScroll() {
        try {
            if(window){
                const height = (window.innerHeight * .10);

                if(window.scrollY <= height && this.state.navChange) { 
                    this.setState({ navChange: false });                    
                }
                else if(window.scrollY > height && !this.state.navChange){
                    this.setState({navChange: true });
                }
            }
        }
        catch(ex){
            //console.log("[Error] listening to Scroll: ", ex);
        }
    }
    
    setSidebarDisplay(status) {
        document.body.classList.toggle('noscroll', status);
        this.setState({ sidebarOpen: status });
    }

    componentDidMount(){ 
        window.addEventListener('scroll', this.listenToScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll);
    } 

    render(){   
        return(
            <Router history={history}> 
                {/* Header */} 
                <MobileNav setSidebarDisplay={this.setSidebarDisplay} sidebarOpen={this.state.sidebarOpen}/>
                <nav className={"navbar navbar-expand-lg" + (this.state.navChange ? " back":"")}>
                    <Link className="navbar-brand" to="/">
                        <div className="initial-wrapper">
                            <div className='txt txt-top'>Rey's</div>
                            <div className='txt txt-bottom'>Rosè</div>
                            <div className='txt mini'>MOBILE BAR</div>
                        </div>
                    </Link>
                    <button className="navbar-toggler" type="button" aria-label="Toggle navigation" onClick={() => this.setSidebarDisplay(true)}>
                        <span className="navbar-toggler-icon"><i className="fas fa-bars"></i></span>
                    </button>

                    <div className="collapse navbar-collapse">
                        <div className="collapse-container">
                            <Link className="nav-item nav-link" to="/services">Services</Link>
                            <Link className="nav-item nav-link" to="/services#drinks">Drinks</Link>                            
                            <Link className="nav-item nav-link" to="/faq">FAQ</Link>
                            <Link className="link-btn glass cg5 lifted" to="/contactus">Book Now</Link>                             
                        </div>
                    </div>
                </nav>
                {/* Body */}
                <Switch>            
                    <Route exact path="/" render={()=> <Home rootPath={rootPath} /> }/>  
                    { routes.map((route, i) => <SiteRoutes key={i} {...route} />) } 
             
                    {/* Default Route */}
                    <Route render={props => (<CatchAllPage rootPath={rootPath} {...props} />)} />                     
                </Switch>

                {/* Footer */}
                <div className="footer">
                    <div className="footer-section">
                        <div className="footer-title main">Rey's Rosè</div>
                        <div className="footer-subtitle plus">Mobile Bar</div>

                        <div className="footer-title">Contact Us</div>
                        <p className="item"><i className="far fa-envelope"/> <span>ReysRoseMobileBar@gmail.com</span></p>

                        <div className="item icon-section">
                            <a href="https://www.instagram.com/reysrosemobilebar/" target="_blank"><i className="fab fa-instagram"/></a>
                            <a href="https://www.facebook.com/reysrosemobilebar" target="_blank"><i className="fab fa-facebook"/></a>
                        </div>
                    </div>

                    <div className="footer-section ctr">                            
                        <img src={logo_c} alt="Rey's Rosè Mobile Bar Logo"/>
                    </div>

                    <div className="footer-section split">
                        <div className="footer-title">Site Resources</div>
                        <div className="link-column">
                            <Link className="item item-link" to="/services">Services</Link>
                            <Link className="item item-link" to="/services#drinks">Drinks</Link>                            
                        </div>

                        <div className="link-column">
                            <Link className="item item-link" to="/faq">FAQ</Link>                            
                            <Link className="item item-link" to="/contactus">Book Now</Link>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div>
                        <i className="far fa-copyright"/>
                        <span>{this.state.copyrightDate}. Rey's Rosè Mobile Bar. All Rights Reserved.</span>
                    </div>
                    <div><span>We Are Fully Licensed & Insured</span></div>
                </div>
            </Router>
        );
    }
}

export default App;