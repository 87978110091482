import React, { Component } from 'react';

class BackArea extends Component{   
    componentDidMount(){ }
    
    render(){        
        return(
            <div className={"back-area " + this.props.colorClass} >
                <ul className="circles">
                    <li/><li/><li/>
                    <li/><li/><li/>
                    <li/><li/><li/>
                    <li/><li/><li/>
                    <li/><li/><li/>
                </ul>
            </div >
        );        
    }
}

export default BackArea;