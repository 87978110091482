import axios from 'axios';
import React, { Component } from 'react';
import ReactGA from 'react-ga';

import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";

class ContactUs extends Component {
    constructor(props) { 
        super(props);
        this.state = {
            currentStep:0, loading: false,
            formData: {
                firstName:"", lastName:"", email:"", phone:"",
                eventDate:'', eventLocation:"", eventAddress:"",
                eventType:"", eventStart:"", eventEnd:"", eventGuestCount:0,
                packageSelection:"", foundUs:"", specialRequests:""
            },
            stepList: [
                { step:1, stepTitle: "Contact Information", fields:[
                    { key:"firstName", default:"", size:5, title:"First Name", required: true, type:"text"},
                    { key:"lastName", default:"", size:5, title:"Last Name", required: true, type:"text" },
                    { key:"email", default:"", size:7, title:"Email", required: true, type:"text" },
                    { key:"phone", default:"", size:3, title:"Phone Number", required: true, type:"text" }
                ]},
                { step:2, stepTitle: "Event Details", fields:[
                    { key:"eventDate", default:"", size:4, title:"Event Date", required: false, type:"date" },
                    { key:"eventStart", default:"", size:3, title:"Anticipated start time?", required: false, type:"time" },
                    { key:"eventEnd", default:"", size:3, title:"Anticipated end time?", required: false, type:"time" },
                    { key:"eventType", default:"", size:4, title:"What type of occasion is this?", required: false, type:"select", options:["Wedding", "Corporate Event", "Non-Profit Event", "Baby Shower", "Bridal Shower", "Bachelorette Party/Bachelor Party", "Anniversary", "Birthday Party"] },
                    { key:"eventLocation", default:"", size:6, title:"Where will the event be held?", required: false, type:"text" },
                    { key:"eventAddress", default:"", size:7, title:"What is the address where the event will be held?", required: false, type:"text" },
                    { key:"eventGuestCount", default:0, size:3, title:"Total guests count?", required: false, type:"number" }
                ]},
                { step:3, stepTitle: "Services", fields:[
                    { key:"packageSelection", default:"", size:10, title:"What package is best for you?", required: false, type:"custom" },
                    { key:"foundUs", default:"", size:10, title:"How did you hear about Rey’s Rosé Mobile Bar?", required: false, type:"select", options:["Instagram", "Facebook", "Our Website", "Word Of Mouth", "Referral", "Repeat Client"] },
                    { key:"specialRequests", default:"", size:10, title:"Any Special Requests?", required: false, type:"textarea" }
                ]},
            ],
            customList:[]
        }

        this.getCustomList = this.getCustomList.bind(this);
        this.formBuilder = this.formBuilder.bind(this);
        this.onElementChange = this.onElementChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.stepChange = this.stepChange.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.cleanForm = this.cleanForm.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.checkSubmit = this.checkSubmit.bind(this);
    }

    getCustomList(){
        var self = this;
        try {
            var postData = { url: "stories/services-drinks" };
            axios.post(self.props.rootPath + "/api/getPageData", postData, {'Content-Type': 'application/json'})
                .then(function(response) {
                    if(response.data.error){
                        console.log(" [Error] Getting Services Page Data (E9): ", response.data.error);
                    }
                    else if(response.data.results){
                        self.setState({ customList: response.data.results.serviceList });
                    }
                }).then(function(){ }); 
        }
        catch(ex){
            console.log("Error getting services page data for form: ",ex);
        }
    }

    formBuilder(field){
        try {
            switch(field.type) {
                case "text":
                    return <>
                        <span className='input-title'>{field.title} {(field.required ? "*" : "")}</span>
                        <input type="text" name={field.key} className="" value={this.state.formData[field.key] || ''} onChange={(e) => this.onElementChange(e)}/>
                    </>;
                case "textarea":
                    return <>
                        <span className='input-title'>{field.title} {(field.required ? "*" : "")}</span>
                        <textarea name={field.key} className="" value={this.state.formData[field.key] || ''} onChange={(e) => this.onElementChange(e)}/>
                    </>;
                case "number":
                    return <>
                        <span className='input-title'>{field.title} {(field.required ? "*" : "")}</span>
                        <input type="number" name={field.key} className="" value={this.state.formData[field.key] || ''} onChange={(e) => this.onElementChange(e)}/>
                    </>;
                case "date":
                    return <>
                        <span className='input-title'>{field.title} {(field.required ? "*" : "")}</span>
                        <Datetime value={this.state.formData[field.key] || ''} onChange={(e) => this.handleDateChange(e, field.key)} displayTimeZone={null} timeFormat={false}/>
                    </>;
                case "time":
                    return <>
                        <span className='input-title'>{field.title} {(field.required ? "*" : "")}</span>
                        <Datetime value={this.state.formData[field.key] || ''} onChange={(e) => this.handleDateChange(e, field.key)} displayTimeZone={null} dateFormat={false}/>
                    </>;
                case "select":
                    return <>
                        <span className='input-title'>{field.title} {(field.required ? "*" : "")}</span>
                        <select name={field.key} value={this.state.formData[field.key] || ''} onChange={(e) => this.onElementChange(e)}>
                            <option value=""></option>
                            {field.options.map((option,j) =>
                                <option value={option} key={j}>{option}</option>
                            )}
                        </select>
                    </>;
                case "custom":
                    return <>
                        <span className='input-title'>{field.title} {(field.required ? "*" : "")}</span>
                        <div className='service-list-container'>
                            {this.state.customList.map((service,j) =>
                                <div className='service-item-container' key={j}>
                                    <div className={'service-item' + (service.title === this.state.formData[field.key] ? " sel":"")} onClick={() => this.handleCustomChange(service.title, field.key)}>
                                        <h3>{service.title}</h3>
                                        <p>{service.description}</p>
                                        <div className='icon-bullet-list'>
                                            {service.itemList.map((item,k) =>
                                                <div className='icon-bullet' key={k}>
                                                    <i className="fas fa-cocktail" />
                                                    <span>{item.info}</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </>;
                default:
                    return <></>;
            }
        }
        catch(ex){
            console.log(`[Error] Building Form: ${ex}`);
        }
    }

    onElementChange(event){
        var self = this;
        try {
            var tmpData = this.state.formData;
            var name = event.target.name;

            if(name in tmpData) {
                tmpData[name] = (event.target.type === 'checkbox' ? event.target.checked : event.target.value);
                self.setState({ formData:tmpData });
            }
        }
        catch(ex){
            console.log("Error changeing element: ",ex);
        }
    }

    handleDateChange(e, key){
        try {
            var tmpData = this.state.formData;
            tmpData[key] = e._d;            
            this.setState({ formData: tmpData });
        }
        catch(ex){
            console.log(" [Error] Handling Date Change: ",ex);
        }
    }

    handleCustomChange(name, key){
        try {
            var tmpData = this.state.formData;
            tmpData[key] = (tmpData[key] === name ? "" : name);          
            this.setState({ formData: tmpData });
        }
        catch(ex){
            console.log(" [Error] Handling Custom Change: ",ex);
        }
    }

    stepChange(dir, direct){
        try {
            var curStep = this.state.currentStep;

            if(direct) {
                curStep = dir;
            }
            else if(dir < 0) {
                curStep = (curStep > 0 ? curStep-1 : curStep);
            }
            else {
                curStep = ((curStep+1) < this.state.stepList.length ? curStep+1 : curStep);
            }

            this.setState({ currentStep: curStep });
        }
        catch(ex){
            console.log("Error changing element: ",ex);
        }
    }

    validateForm(){
        var ret = [];

        try {
            for(var i=0; i < this.state.stepList.length; i++){
                for(var j=0; j < this.state.stepList[i].fields.length; j++){
                    var tmpField = this.state.stepList[i].fields[j];
                    if(tmpField.required && this.state.formData[tmpField.key].length === 0) {
                        ret.push(`'${tmpField.title}'`);
                    }
                } 
            }
        }
        catch(ex){
            console.log("[Error] validating form: ",ex);
        }

        return ret;
    }

    cleanForm(){
        try {
            var tmpFormData = {};
            for(var i=0; i < this.state.stepList.length; i++){
                for(var j=0; j < this.state.stepList[i].fields.length; j++){
                    var tmpField = this.state.stepList[i].fields[j];
                    tmpFormData[tmpField.key] = tmpField.default;
                } 
            }

            this.setState({ formData: tmpFormData, currentStep: 0 });
        }
        catch(ex){
            console.log("[Error] validating form: ",ex);
        }
    }

    submitForm(){
        var self = this;
        try {
            var tmpValidation = this.validateForm();

            if(tmpValidation.length > 0) {
                alert(`Please make sure all required fields are entered. \n\n Verify the following field(s) are filled: \n${tmpValidation.join(" ,\n")}`);
            }
            else {
                this.setState({ loading: true}, () =>{
                    var postData = { form: self.state.formData };
                    // Submit Form
                    axios.post(self.props.rootPath + "/api/sendFormEmail", postData, {'Content-Type': 'application/json'})
                        .then(function(response) {
                            if(response.data.error){
                                console.log(" [Error] Sending Form Email (E1): ", response.data.error);
                                alert("Sorry there was an issue sending your request, while we work on this please follow up with us via email: ReysRoseMobileBar@gmail.com")
                            }
                            else if(response.data.results){
                                alert("Your Request has been submitted we will get back to you as soon as we can.")
                                self.cleanForm();
                            }
                        }).then(function(){
                            self.setState({ loading: false });
                        }); 
                });
            }
        }
        catch(ex){
            console.log("[Error] submitting form: ",ex);
        }
    }

    checkSubmit(e){
        try {
            if (((this.state.currentStep + 1) === this.state.stepList.length) && e.key === 'Enter') {
                e.preventDefault(); e.stopPropagation();
                this.submitForm();
            }
        }
        catch(ex){
            console.log(`[Error] Checking Submit: ${ex}`);
        }
    }

    componentDidMount(){ 
        window.scrollTo(0, 0); document.title = "Contact Us - Rey's Rosè Mobile Bar";
        this.getCustomList();
        // Google Analytics
        ReactGA.initialize('UA-220465582-1'); ReactGA.pageview('/contactus');
    }
    render(){  
        return(
            <div className="page-body sub contact-us">
                {this.state.loading && <div className="lds-ripple"><div/><div/></div> }
                {/*<div className="back-alt fx" /> */}
                <div className='contact-title'>
                    <h1>Book Now</h1>
                    <p>Let's get started!! We'd love to be a part of your special event! If you're ready to book, have questions, want to create a custom package, just fill out our form below, give us all the details and we'll connect with you within 48 hours.</p>
                </div>

                <div className='contact-container'>
                    <div className='step-container'>
                        <h2>Book Consultation Form</h2>
                        <p>Please help us by giving us alittle more information so that we can discuss the next steps to building your event.</p>

                        <div className='step-tool'>
                            {this.state.stepList.map((step,i) =>
                                <div className={'step-item' + (this.state.currentStep === i ? " active" : "")} key={i} onClick={() => this.stepChange(i, true)}>
                                    <span className='step-bullet'>{step.step}</span>
                                    <span className='step-title'>{step.stepTitle}</span>
                                </div>
                            )}
                        </div>
                    </div>
                    <form className='form-cap' onKeyDown={this.checkSubmit}>
                        <div className='form-container'>
                            {this.state.stepList[this.state.currentStep].fields.map((field, i) =>
                                <div className={`input-field sz-${field.size}`} key={i}>{this.formBuilder(field)}</div>
                            )}
                        </div>
                        <div className='step-ctrl-container'>
                            <div className={'step-ctrl' + (this.state.currentStep === 0 ? " inactive" : "")} onClick={() => this.stepChange(-1, false)}>Prev</div>
                            <div className={'step-ctrl' + ((this.state.currentStep + 1) >= this.state.stepList.length ? " inactive" : "")} onClick={() => this.stepChange(1, false)}>Next</div>
                        </div>

                        {(this.state.currentStep + 1) === this.state.stepList.length && 
                            <div className='btn-container'><div className="submit-btn link-btn c6 pulse" onClick={this.submitForm}>Submit</div></div> 
                        }
                    </form >
                </div>
            </div>
        );
    }
}
export default ContactUs;