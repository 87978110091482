import axios from 'axios';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import ReactGA from 'react-ga';

import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import SwiperCore, { Navigation, EffectCoverflow } from 'swiper';
SwiperCore.use([Navigation, EffectCoverflow]);

/* Components */
import BackArea from '../shared/backArea';

/* Images */
import back1 from '../../assets/imgs/landing12.jpg';
import penas from '../../assets/imgs/penas.jpg';
import t1 from '../../assets/imgs/rosie.jpg';
import t2 from '../../assets/imgs/rosie2.jpg';
import t3 from '../../assets/imgs/rosie3.jpg';

import defaultImg from '../../assets/imgs/sketch3.png';

class Home extends Component{
    constructor(props) { 
        super(props);
        this.state = { 
            loading: false, highlightList:[]
        }

        this.cleanText = this.cleanText.bind(this);
        this.createDynamicList = this.createDynamicList.bind(this);
        this.getPageData = this.getPageData.bind(this);
    }  

    cleanText(text, textMax){
        var ret = "";
        try {
            ret = (text.length > textMax ? text.slice(0, textMax) + "..." : text);
        }
        catch(ex){
            console.log(`[Error] cleaning text: ${ex}`);
        }

        return ret;
    }

    createDynamicList(n){
        var arr = [];
        try {
            for(var i=0; i< n; i++) { arr.push(""); }
        }
        catch(ex) {
            console.log(`[Error] Generating Dynamic List: ${ex}`);
        }
        return arr;
    }

    
    getPageData(){
        var self = this;
        try {
            this.setState({ loading: true }, ()=> {
                var postData = { url: "stories/home-page" };
                axios.post(self.props.rootPath + "/api/getPageData", postData, {'Content-Type': 'application/json'})
                    .then(function(response) {
                        if(response.data.error){
                            console.log(" [Error] Getting Home Page Data (E1): ", response.data.error);
                        }
                        else if(response.data.results){
                            self.setState({ 
                                highlightList: response.data.results.highlightList
                            })
                        }
                    }).then(function(){
                        self.setState({ loading: false });
                    }); 
            });
        }
        catch(ex){
            console.log("Error getting home page data: ",ex);
        }
    }

    componentDidMount(){ 
        window.scrollTo(0, 0); document.title = "Rey's Rosè Mobile Bar";
        // Google Analytics
        ReactGA.initialize('UA-220465582-1'); ReactGA.pageview('/');
        this.getPageData();
    }
    render(){  
        return(
            <div className="page-body home">
                <section className="landing-card">
                    <div className="landing-img"><img src={back1} alt="Rey's Rosè Mobile Bar Main Background" /></div>
                    <div className="landing-txt">
                        <h1>Don't worry, we'll come to you</h1>
                        <h2><span>Special events</span> call for even more <span>special drinks</span></h2>
                    </div>
                </section>

                <section className='base-style alt5'>
                    <BackArea colorClass={"c4"} />
                    <div className="title-area alt5">
                        <h2>About Us</h2>
                        <h1>Who We Are</h1>
                        <b className="hr anim alt" />
                    </div>

                    <div className='content columns'>
                        <p>
                            We are <b>Rey's Rosé  Mobile Bar</b>. A unique bartending service. We are family owned  and pride ourselves in giving you and your guest  the best experience. Our mobile bar will bring your special event to the next level and provide you and your guest a custom and memorable experience.
                        <br/>We are excited to talk more about what we offer, talk about our services and answer any questions you may have.
                        <br/><br/>Cheers to planning and celebrating your next  memorable occasion!
                        </p>
                    </div>
                </section>

                <section className='base-style'>
                    <div className="title-area alt3">
                        <h2>Our Trailer</h2>
                        <h1>"Rosie"</h1>
                        <b className="hr anim" />
                    </div>

                    <div className='split-img grid'>
                        <div className='grid-img'><img src={t3} alt="Our Trailer 'Rosie'" /></div>
                        <div className='grid-img'><img src={t2} alt="Our Trailer 'Rosie'" /></div>
                        <div className='grid-img'><img src={t1} alt="Our Trailer 'Rosie'" /></div>
                    </div>

                    <div className='content'>
                        <p>Rosie is the name we gave to our  beautiful Mobile Bar. After a long and arduous search for the right trailer to make our Mobile Bar,
                        we finally found the one. The <b>1986 Horse Trailer</b> we transformed into "Rosie".</p>
                        <p>The transformation took hard work and dedication. My husband and I took our time and made sure we executed our
                        vision. She is a charmer!! She brings a vintage classy look anywhere she goes.</p>
                    </div>
                    
                    <div className='full-text'>
                        <p>The best part about it all is we get to share Rosie with you and be a part of special and memorable moments that will be
                        talked about in your family for generations.</p>
                        <p>
                            <Link className="link-btn glass cg5 lifted" to="/contactus">Book Now</Link>
                        </p>
                        <p><b>Now Accepting Bookings for 2022 & 2023. Cheers!</b></p>
                    </div>
                </section>

                <section className='base-style'>
                    <BackArea colorClass={"c1"} />
                    <div className="title-area">
                        <h2>Owners</h2>
                        <h1>About Us</h1>
                        <b className="hr anim alt" />
                    </div>
                    <div className='content alt'>
                        <p>Hi there!! <b>I'm Rocío</b>. Let's talk about how it all started. The idea of one-day owning a Vineyard was a dream of My
                        Husband and mine, but we knew we needed to be a bit more realistic as to where we could start. Light bulb went off
                        and I started doing my research on mobile bars. Everything just clicked. It was then I knew exactly how to make our
                        dreams come true.</p>
                    </div>
                    <div className='split-img left'><img src={penas} alt="Owners"/></div>
                </section>

                <section className="follow-us">
                    <div className="title-area alt3">
                        <h2>Our Creations</h2>
                        <h1>Bar Highlights</h1>
                        <b className="hr anim" />
                    </div>

                   <div className='post-container2'>
                       <Swiper effect="coverflow" centeredSlides={true} slidesPerView={'auto'} spaceBetween={20}
                            coverflowEffect={{ rotate: 50, stretch: 0, depth: 100, modifier: 1, slideShadows: false }}
                            navigation={true} pagination={false} loop={false} className="mySwiper">
                            {!this.state.loading && this.state.highlightList.map((item, i) => 
                                <SwiperSlide key={i}>                                    
                                    <img src={(!item.image ? defaultImg : item.image)} alt="Custom Drinks" />
                                    {(item.title != null && item.title.length > 0) &&
                                        <div className='text'>
                                            <span>{this.cleanText(item.title, 100)}</span>
                                        </div>       
                                    }         
                                </SwiperSlide>
                            )}

                            {this.state.loading && this.createDynamicList(5).map((item,j) => 
                                <SwiperSlide key={`e-${j}`}><div className='empty-post empty-animate' /></SwiperSlide>
                            )}

                            {(!this.state.loading && this.state.highlightList.length < 4) && this.createDynamicList(4 - this.state.highlightList.length).map((item,k) => 
                                <SwiperSlide key={`d-${k}`}><div className='empty-post default' /></SwiperSlide> 
                            )}                       
                        </Swiper>
                    </div>
                </section>
            </div>
        );
    }
}
export default Home;