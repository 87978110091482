import axios from 'axios';
import { Link } from "react-router-dom";
import React, { Component } from 'react';
import ReactGA from 'react-ga';

/* Components */
import BackArea from '../shared/backArea';

/* Images */
import back from '../../assets/imgs/drinks2.jpg';
import pour from '../../assets/imgs/pour.jpg';
import drink from '../../assets/imgs/drinks3.jpg';
import drink1 from '../../assets/imgs/drinks3-1.jpg';
import drink2 from '../../assets/imgs/drinks3-2.jpg';


class Services extends Component{
    constructor(props) { 
        super(props);
        this.state = { 
            serviceList: [], cocktails:[],
            bubbly: [], mocktails:[], loading: true
        }

        // Ref
        this.cocktailPrevRef = React.createRef();
        this.cocktailNextRef = React.createRef();

        this.pageScrollCtrl = this.pageScrollCtrl.bind(this);
        this.getPageData = this.getPageData.bind(this);
    }

    pageScrollCtrl(){
        try {
            if(window.location.href.indexOf("#drinks") > -1) {
                document.getElementById("drinks").scrollIntoView({ behavior: 'smooth' });
            }
            else {
                window.scrollTo(0, 0);
            }
        }
        catch(ex){
            console.log(`[Error] scrolling page: ${ex}`);
        }
    }

    getPageData(){
        var self = this;
        try {
            this.setState({ loading: true }, ()=> {
                var postData = { url: "stories/services-drinks" };
                axios.post(self.props.rootPath + "/api/getPageData", postData, {'Content-Type': 'application/json'})
                    .then(function(response) {
                        if(response.data.error){
                            console.log(" [Error] Getting Services Page Data (E1): ", response.data.error);
                        }
                        else if(response.data.results){
                            self.setState({ 
                                serviceList: response.data.results.serviceList, 
                                cocktails: response.data.results.cocktails,
                                bubbly: response.data.results.bubbly,
                                mocktails: response.data.results.mocktails
                            })
                        }
                    }).then(function(){
                        self.setState({ loading: false });
                    }); 
            });
        }
        catch(ex){
            console.log("Error getting services page data: ",ex);
        }
    }

    componentDidMount(){ 
        this.pageScrollCtrl();         
        document.title = "Services & Drinks - Rey's Rosè Mobile Bar";
        // Google Analytics
        ReactGA.initialize('UA-220465582-1'); ReactGA.pageview('/services');
        this.getPageData();
    }
    render(){  
        return(
            <div className="page-body sub services">
                <div className='sub-title c2'>
                    <h1 className="lrgTitle c0" data-text="Services">Services</h1>
                    <div className='cover-img'><img src={back} alt="Our Services Cover"/></div>
                </div>

                <div className='sub-section title-top our-services c6'>
                    <div className='split-img-container'>
                        <div className='content'>
                            <h2>Our Goal</h2>
                            <p>For your event let us take care of whats on the menu for drinks with our packages including <span>Mobile Bar Rental</span>, <span>1 to 2 certified bartenders</span>, as well as: </p>
                            <div className='icon-bullet-list'>
                                <div className='icon-bullet'>
                                    <i className="fas fa-glass-whiskey" />
                                    <span>All Glassware (plastic), Napkins, Ice, & Bar equipment</span>
                                </div>

                                <div className='icon-bullet'>
                                    <i className="fas fa-tint" />
                                    <span>Fruit, Juice, & Water Jug Stations</span>
                                </div>

                                <div className='icon-bullet'>
                                    <i className="fas fa-map" />
                                    <span>Custom Bar MENU & detailed shopping list</span>
                                </div>

                                <div className='icon-bullet'>
                                    <i className="fas fa-clipboard-check" />
                                    <span>Insurance (General & Liquor Liability)</span>
                                </div>
                            </div>
                        </div>

                        <div className='split-img'><img src={pour} alt="Service Drink"/></div>
                    </div>

                    <div className='service-list-container'>
                        {/* Default */}
                        {!this.state.loading && this.state.serviceList.map((service,i) =>
                            <div className='service-item-container' key={i}>
                                <div className='service-item'>
                                    <h3>{service.title}</h3>
                                    <div className='img-container'><img src={service.image} alt="service" /></div>
                                    <p>{service.description}</p>
                                    <div className='icon-bullet-list'>
                                        {service.itemList.map((item,j) =>
                                            <div className='icon-bullet' key={j}>
                                                <i className="fas fa-cocktail" />
                                                <span>{item.info}</span>
                                            </div>
                                        )}
                                        <Link className='icon-bullet link' to="/faq?q=alcohol">
                                            <i className="fas fa-question" />
                                            <span>Alcohol Not Included</span>
                                        </Link>
                                    </div>

                                    <div className='price'>{service.pricing}</div>
                                </div>
                            </div>
                        )}

                        {/* Loading */}
                        {this.state.loading &&
                            <>
                                <div className='glimmer alt no-service' />
                                <div className='glimmer alt no-service' />
                                <div className='glimmer alt no-service' />
                            </>
                        }
                    </div>

                    <BackArea colorClass={"c8"}/>
                </div>

                <div className='sub-section popular-drinks' id="drinks">
                    {/*<div className="back-alt" /> */}
                    <div className='sub-section-title'>
                        <h1 className="lrgTitle c0" data-text="Drinks">Popular Drinks</h1>
                        <p>We always aim to create a custom drink experience that matches your event, below are some of our popular drinks & mixes that can be brought to your event.</p>
                    </div>
                    
                    <div className="drink-menu-section">
                        <div className='menu-img-container left'>
                            <img src={drink} alt="Cocktail List"/>
                            <div className='img-back'><BackArea colorClass={"c8"}/></div>
                        </div>
                        <div className='menu-content'>
                            <h2>Cocktails</h2>
                            <div className='drink-list'>
                                {!this.state.loading && this.state.cocktails.map((item, i) => 
                                    <div className='drink-item' key={i}>{item.title}</div>
                                )}    
                            </div>
                        </div>
                    </div>
                    
                    <div className="drink-menu-section flip">
                        <div className='menu-content'>
                            <h2>Bubbly</h2>
                            <div className='drink-list'>
                                {!this.state.loading && this.state.bubbly.map((item, i) => 
                                    <div className='drink-item' key={i}>{item.title}</div>
                                )}    
                            </div>
                        </div>
                        <div className='menu-img-container right'>
                            <img src={drink2} alt="Bubbly List"/>
                            <div className='img-back'><BackArea colorClass={"c8"}/></div>
                        </div>
                    </div>

                    <div className="drink-menu-section">
                        <div className='menu-img-container left'>
                            <img src={drink1} alt="Mocktail List" />
                            <div className='img-back'><BackArea colorClass={"c8"}/></div>
                        </div>
                        <div className='menu-content'>
                            <h2>Mocktails</h2>
                            <div className='drink-list'>
                                {!this.state.loading && this.state.mocktails.map((item, i) => 
                                    <div className='drink-item' key={i}>{item.title}</div>
                                )}    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Services;