import axios from 'axios';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import queryString from 'query-string';

/* Components */
import BackArea from '../shared/backArea';

/* Images */
import back from '../../assets/imgs/faq.jpg';

class FAQ extends Component {
    constructor(props) { 
        super(props);
        this.state = { 
            faqList: [], loading: false
        }

        this.questionCheck = this.questionCheck.bind(this);       
        this.getPageData = this.getPageData.bind(this); 
    }

    questionCheck(){
        try {
            var param = queryString.parse(this.props.location.search);

            if("q" in param && param.q.toLowerCase() === "alcohol"){ 
                if(document.getElementById("heading-x")) {
                    document.getElementById("heading-x").scrollIntoView({ behavior: 'smooth' });
                    document.getElementById("toggle-btn-x").click();
                }
            }
            else {
                window.scrollTo(0, 0); 
            }
        }
        catch(ex){
            console.log(`[Error] scrolling page: ${ex}`);
        }
    }

    getPageData(){
        var self = this;
        try {
            this.setState({ loading: true }, ()=> {
                var postData = { url: "stories/faq" };
                axios.post(self.props.rootPath + "/api/getPageData", postData, {'Content-Type': 'application/json'})
                    .then(function(response) {
                        if(response.data.error){
                            console.log(" [Error] Getting FAQ Page Data (E1): ", response.data.error);
                        }
                        else if(response.data.results){
                            self.setState({ faqList: response.data.results.faqList });
                        }
                    }).then(function(){
                        self.setState({ loading: false }, ()=>{
                            self.questionCheck();
                        });
                    }); 
            });
        }
        catch(ex){
            console.log("Error getting FAQ page data: ",ex);
        }
    }

    componentDidMount(){ 
        document.title = "FAQ's - Rey's Rosè Mobile Bar";
        this.getPageData();
        // Google Analytics
        ReactGA.initialize('UA-220465582-1'); ReactGA.pageview('/faq');
    }

    render(){  
        return(
            <div className="page-body sub faq">
                <div className='sub-title c1'>
                    <h1 className="lrgTitle c0" data-text="FAQ's">Frequently Asked Questions</h1>
                    <div className='cover-img'><img src={back} alt="Frequently Asked Questions Cover" /></div>
                </div>

                <div className='sub-section title-top faq-list c1'>
                    <div className="accordion" id="faqAccordion">
                        {/* Default */}
                        {!this.state.loading && 
                            <>
                                {
                                    this.state.faqList.map((item,i) => 
                                        <div className="card" key={i}>
                                            <div className="card-header" id={"heading-"+i}>
                                                <h2 className="mb-0">
                                                    <button className="btn btn-link" type="button" data-toggle="collapse" data-target={"#collapse-"+i} aria-expanded="false" aria-controls={"collapse-"+i} id={"toggle-btn-"+i}>
                                                        <i className="fas fa-plus" /> <span>{item.question}</span>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id={"collapse-"+i} className="collapse" aria-labelledby={"heading-"+i} data-parent="#faqAccordion">
                                                <div className="card-body">{item.answer}</div>
                                            </div>
                                        </div>
                                    )
                                }
                                <div className="card">
                                    <div className="card-header" id="heading-x">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapse-x" aria-expanded="false" aria-controls="collapse-x" id="toggle-btn-x">
                                                <i className="fas fa-plus" /> <span>Do You Provide The Alcohol?</span>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapse-x" className="collapse" aria-labelledby="heading-x" data-parent="#faqAccordion">
                                        <div className="card-body">We can't. <b>State law prevents us</b> from providing the alcohol. Event hosts are responsible for the purchase of all alcohol, but <b>no worries we make it easy</b> for you, by providing you with a shopping list of exactly what's needed based on the menu we customize for you.</div>
                                    </div>
                                </div>
                            </> 
                        }

                        {/* Loading */}
                        {this.state.loading &&
                            <>
                                <div className='glimmer alt no-question' />
                                <div className='glimmer alt no-question' />
                                <div className='glimmer alt no-question' />
                                <div className='glimmer alt no-question' />
                            </>
                        }            
                    </div>

                    <BackArea colorClass={"c1"}/>
                </div>
            </div>
        );
    }
}
export default FAQ;